<template>
  <svg
    id="dowlek"
    :width="width"
    :height="height"
    data-name="dowlek"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 429.08 369.88">
    <defs>
      <image
        id="image"
        width="104"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAABjCAYAAAB320ViAAAACXBIWXMAAAsSAAALEgHS3X78AAADMUlEQVR4Xu3cMU/bQByG8ed8OakqSyKUziysDCRDd5Z+Ar5CPhPfqAMsXbqyVWqE4qVdjH0dYkMSCKYVhTfh/c0OkfJwvvM/iJBzxnQVfRfY23IgcQ4kzoHEOZA4BxLnQOIcSJwDiXMgcQ4kzoHEOZA4BxLnQOIcSJwDiXMgcQ4kzoHEOZA4BxLnQOIcSJwDiXMgcQ4kzoHEOZA4BxLnQOIcSJwDiXMgcYO+C97abBrSz5qDT5FfF5e56rt+30ivoNk0pJuaow+ByaJh2Hf9PpJdQV2cwYBJAxV13yv2k+QK6uKkAZ9zZphrvo8Kyr7X7SPJQIuG4WDApMkcAUQo3+P+A4KBZtOQgDGZMZD6rt93coEWDcMicpJ5n4eCTXKBIqScGeLVAwgGsnVSgWbTkGoYBq+eO1KBvP88JBXI+89DUoHsIQcSJzWLq6EqAmV+xYfU2TSkRcMwQqqhGhVaUwupFTQqKJuab4H7uVtYfnDDdsLworqZXxE5ayJfIpws4GPf616TVKCLy1xFKDPc/QZnlie7l/66YWMgexwyBzX8GMHvvte+JqlAW6R2Ljd+yVW0MZBNGSrFoaxcoBqqECj5j6vokYFsFQJlvfKeKuQCPbYP8UKraDYN6fw0jBcNxzEy6R6IA8v3VPzOSS5Qe4uZE5izsYpiZHJTc/QvkVYPBEXBWXdrA6r2veZqtzcQO2Z3RgXlzS1XaUBa+SBTkzlKA1jUpPPTMH/ukXj1QLDy82B5rL+ubrk6jHqrByCo/mPzpz7UACWBeVPzDdgaqnvGAcYx3h8Iup/Txvl6GLl+Tui3IBsInowEG6EiD1dA3R4uyIzb/Wan4oB4IOiNBG2o1WenTlgenzeHrzsTB3YgEKz/CdYjq+G57lbc7XLPkY8DOxII1veTLbetbZ69ZynamUCdzVDt90dbhbB8xmHHwnR2LlBndQr91HWKE+q/sbOB3gu5SYKtcyBxDiTOgcQ5kDgHEudA4hxInAOJcyBxDiTOgcQ5kDgHEudA4hxInAOJcyBxDiTOgcQ5kDgHEudA4hxInAOJcyBxDiTOgcQ5kDgHEudA4hxInAOJcyBxfwD7OFIPmFDGFAAAAABJRU5ErkJggg=="/>
      <image
        id="image-2"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADMUlEQVR4Xu3cMXLTQBiG4Xe11gwzNPYwpnaTNgV2QZ87cAWfKVfgDjRUFAkFLY1rPBm7oZKlpbCMZbBGBHDy2freMlk3eqLVr/VMQkoJp1nWtcA9X8YRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRbtC14Dmbz0L+reTl68j327tUdK2/tGTvnPks5A8lkxeB6api2LX+EpPE2cHkA96WkUmEvOszl5gkzqpiOBgwrRKTkPoJA4I481nIgTGJMT29Y3bJ4awqhlnkOtHP50wzOZwIeUoM6fldA2I481nISxgGwwBiON7SDpPCObKlFSGwLqF3L6AghvNrAdZVyZdRxrpr7SUmjZOgiLDu49ENiOMEyEsY1u8+vUsaJ7EdEPp6tiZ9Kk09IJzybG0+C/kOf5RpbaHqOCdtd8A6GDBNUKxKPgLLrs89VVLbWlmPzjRG51M9d5on3ylxRWJ8yjv0b5LCGWXb0TmwH51P8dxpwlSJCYDi+5QUzu1dKiKs0+FFyusT6vH/uHuOwOSq71NSOHB8a0swjJHpQ8nkX4COwQAFgSWwVBoGQBBnlLHebLjPAgv2QHmVthf1b4FaYKRPIYLi/5Wux9urLOMmwbjxqyILLIoNn15FFn/yl94Ylccxbr9d5fDs7mtV8uH95yQzpe2SxAF49yaMs8hNSlxxOEUVWWBRltxHtg/xY+8nTZQsck1iXJ92/4R5LPRTJ4vTtg3VFaEeHELYbkuRw22prKe8IyhwBjAgjAOHL4ktFxkaUM0fBsjb1p8DDIjjQOcz4zEVAdYElpsN9+owcAY4uw6OWtL2q+yWO6PZb9sfsFQ7Q2vrbHBgfxfF+kgni1zX35werfk8ahsclDsrnGZNqLY15wjS7Gxx+pDcCYHbZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhfgADrlzx8WEh3QAAAABJRU5ErkJggg=="/>
      <image
        id="image-3"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAAC/0lEQVR4Xu3csW7aUBiG4ffYPlWlLqCIziwdm6EwdOrSe+gtcE25ow7J0ivIXBThpROxTwfsxCCIUYXKZ/jeMXJkyQ//8clBSkgp4TTL+i5w58s4whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whV9F5yzxTzE3xUfPub8ubtP677rLy3pyVnVjN5nfF7VjPquvcRkcRbzEIFJgkkOse/6S0xyWVvMQ3yqmMaCWQXv8qrvNy4zyclZ1YyKglmdmIZ0nVMDgjjtckZiwpUuZ21yOKuaUZZzm7jOTUA3OZwcYkqMuPKpATGcxTzECkbBMIAYjpe07aRwvKRtJ4XjtjOOcMYRTvL45n+2mIfYHqyOM0ql02+pyalgHQIl8PKAAsQKRs3JwUlrz/CynO8h55va6bcUzjijrCt+BSjbnyU22+tTP7jO4erXlPhE0jv9lsK5u0/rHMrUmRwgNudsk1NNTxemTkwRQ2mTwoH9S9spp+cAzDoEymr7Q3H25HD2LW2caHoOTUxgc89xtnXPsyeH0+yWlgSW7ExPnjN7qpj+C9AbS9m6uddSaacGolvpcUb59MxDLIidBxnrxDQWsKqIP76E5TFb385WeRKbL/DowGSBx/UzDze51tQABNV/+v3WJz1ASWBZV/wC9iJ1UbKcWxKT5kB1F+bnTc5jH/I5ksWB3l3VFlK+/Y6iajYRe1BgADAgjgO9QNAg7Wy/CRD3oMBAYGAAOPAKVBTMDkzCMb1M2vPmHSMNAwPBgaPeIYc66h2l2GBw2naRmi/nDhbC5m8YBoTSNjicthap7zysgvXQUNoGi3MNyZ0QuNeMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI9xfQo89xD5z+fAAAAAASUVORK5CYII="/>
      <image
        id="image-4"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADRklEQVR4Xu3cvW7TUByG8ef4xBISi6vKDExZWDs0HZhYuAckriDX1FvgHliYGFoGVpbORFWyMLn2YbBDnDaR6UfUN837jJXTIb+c45O/q4aUEk6zbOgC93wZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZR7jR0AXP2fQs5L9rXr+J/Dm/SNXQ9S8t2ZUzPQv5dc34VWAybyiGrn+JSeIsYfIR7+vIOEI+9JqXmCTOvKEYjZg0iXFIhwkDgjjTs5ADJYmSA10xy+Rw5g1FFjlJHOZ9pp8UzoZVU4XAooaDO6mBGM7tVRNg0dT8PMpYDL32JSaFEyFPiYLuXpOgirA4xO84IIbj1jOOcMYRzjjCSeMEyGsouiP2wSU9lU60R+t5zQyYDV1/36ZnIZ83FLH9EFRHmdbJUBqH7mi9q8HnvKEIkQ9NogyB2bzmGzv4EDw0qW2t7iYC9CYCu9raltOIkHgLlGFHH4DHJIVzlLUTgcBqIvBva3vCZzrLRxIxMklQqE4ipHDOL1IVYZHWZ2l5N2srn2L19J8VNYkxkKtOIqRwYPPWlqCIkcl1zfgxQJtgEB6uyuFs2tqAvEntm/pQoC0w0sPVoPh/pT+dhjKLfEyJd6zfqKsscFXd8P04cvU/29DyuAyUMbZPV1n9zioEfjU1X7/8SDKntGWSR+mjjMX1DZf5qF0xrN7M5QpiXpN/Pg2Lbd9P+ihZ5IRE2fQm3qygL4+j3qoB0ZUD27ehrip0B4cQ2m0prm+D1N0pj0TZPR961Ap8jmRxYAU0GjHZ8iZDD6r/w9Cewu6gBFgQmN20K0YWBsRxYPCecZ+qLHBV11wCM7VRzabkcZb1V1FKFFtWxu3Wtr99WC399gYH7gwq2783SNsnB/37keJgc6i9wunXh9p2zT6C9NtbnENIbkLgVhlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHuL+bOFaEYo4kFwAAAABJRU5ErkJggg=="/>
      <image
        id="image-5"
        width="104"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAABjCAYAAAB320ViAAAACXBIWXMAAAsSAAALEgHS3X78AAADEUlEQVR4Xu3cMXKbQACF4X9BNKnQZOjdpHVhq0iVY2QmJ9CZfKMUdpMTuEuh8YgqDYJNAVhIlkwmUuKH/P7SAhV87MKuPQ4xRpxuydgB7m0zkHgGEs9A4hlIPAOJZyDxDCSegcQzkHgGEs9A4hlIPAOJZyDxDCSegcQzkHgGEs9A4hlIPAOJZyDxDCSegcQzkHgGEs9A4hlIPAOJZyDxDCSegcQzkHizsQPesuUiZOuGHGCeUN7dx2rsnEtLdgQtFyF7qrkKKV9SuF7Dh7FzLjFZoHVDPptxS6So4eccfo2dc4lJAi0XIQMKIgVAyvuc3kAUaN2QJynXkfb5856TA9obPdnY8ZeeHJBHz25yQClkMZLj0QMIArndpICWi5DVkAePnuekgPz8eZkUkJ8/L5MCci+T2iytoUoCZfzPayDlTVmpETRPKJuaHwHK/mcBshrybgF79tQ3ZaWA7u5jlUIZ4fkOjrQvDv0dfs56nGzGZ9VNWSmgI2UxkqdnnvKGOE3kCjQ3ZeWAaqhCoGQwis49zR3AOcv3/ovkgA49hyLkacrtU83VqUhHcKoQKOvBTaGSHFA3xawIrNhesKyJ7UU9BenYyAm0N8U82d4UKskBweFRxIlIr0xrVXczrNSePyAKdGQUwV8gLRch+3oTinXDp0M4SeBxs+FBcfSA2EJ12DyhfNrwkM1aFLYXtUdiXZN9uwkltC8Xw0XmYPFZJCnXRIpmdxupSgKP1YbvH1MeFUcPQFD+z/Mjb1tVGKyZQminxbSbFutu/USk6DZfd86dAg6IA8EWqf8LnwMXu28XDLIjx04GByYABIenq1egjlUFKAmsNhsepoADEwHq24eKsf3l3p+Mqn4KBFZqG6KvNSmgvh4q7XYYeqz944bPpf2XiKk0SaBhQ6z9z6aKMmzyQJee5ELVbTOQeAYSz0DiGUg8A4lnIPEMJJ6BxDOQeAYSz0DiGUg8A4lnIPEMJJ6BxDOQeAYSz0DiGUg8A4lnIPEMJJ6BxDOQeAYSz0DiGUg8A4lnIPEMJJ6BxDOQeL8BI4Na80OcU5oAAAAASUVORK5CYII="/>
      <image
        id="image-6"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADQElEQVR4Xu3cvW7aUACG4ffYPlKlLqCKzixdOwSGXklugWvqLfQeunTq0HTI2iVzrQiWTmCfDtjBkB9HSRAf8L1rTAYen99ICSklnGZZ3wPucBlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHuKLvgUM2m4b4t+L9x5x/X3+lZd/zp5bsyJlNQ7ytGL8LTOY1g77nTzFJnBYmFnypcsY5xL7PnGKSOPOaQVEwqRPjkM4TBgRxZtMQgRGJEWc6YtrkcOY1gyznc+I815luUjgeNdtJ4XjUbCeFk0NMiQGbUbMMgUUFZ3fGATGc3QIs6orrYcai79lTTBonwTKHxTneDoA4zrknjRMgVjBodnFnl/TFZ2K9e5tXlEDZ9/xLmk1DbO/uhpnWFCqNQ7N729fdWnuHVxRMEiznFT/Y00vwktRx9lb3crVOjIFyXy/BS5Nac6rmXEPnXLOvdad7uQqgeJ6Swhlm63NNYHOuSTDIcya3FeO3Atq9JlI9T0nhNItxSaBk8xbHOq3Xhbf4o1s7neU5k/aaSPU8JYUDD48eIDZv+eg1o+eBdSYifEUkh/PI6HnV9Dabhnh5EUbzmk+7MFngZrXiSm1KA9Hd2jBjcbviKhbrKY31FxnrxDgWMK+IlxehfM65pLtdJjGqOxerARZVxdWHnJu+33OIguo//X5kCgJYBlgQKOuK65z1lNSFag+WebPTy/O7XVl3xC1D4E9d8f3b7yRztukmiwNPAkGDlJo1o4UCqJqbhZQYBIjNwr/12Sxws1zxU3XUgDgO9AK13UHB+mz0AMjdcwTK1Up3OmuTx4H768YjX/xTbU2FwLPWq0N3FDiwdUE56pmy2u5NexwJStvR4LTtLvYt1O5z3XVod8NwLB0dTrcu1O7PjhWk21HjnHpyNwRuk3GEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGE+w+Slmpap+OZ0gAAAABJRU5ErkJggg=="/>
      <image
        id="image-7"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADIElEQVR4Xu3csW7aQBzH8e/5bKlSF6LKHTKxdM0QGDpl6TvkFXimvFGHZOkTZC6KYOlk7OuAHQyCkihR+jP8vmN0YuDD/X0cUkJKCadZdmyB+38ZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZR7j82IL/2Wwait81n79G/tzdp+rY+lNLdufMpqF4qhl/CkwWDaNj608xWZxFwyjPmdSRcYTi2PpTTHKszaahAEoSZYCzG2ddkjtn0TDKIleJ8xxnXXI4/V3DmY6zLjkc75pNcjgRipQYcea7BsRwZtNQ1DAKhgHEcDzStpPC8UjbTgrHbWcc4SRvCD6y2TQU3d3dRcZS6YJVaufUUIXAkg+8slk0jELkJkRu1C5YpXAuMpZNza8Ay+5vAYoaRu3NwbvW3UaExCWJUu2CVQrn7j5VEZapt3MS6+P1e3+qu58kYmSienSXwjlQ0d6zle+1ezqYIud7kxgDhMCy/sBx+pLkcPY9dxKMYmTyVDN+K9AemCKwHqcX2WacKiSHs++5AxRNYpznb/tVdB8MUBGYA3OlkxoI4rRv0Lx9w/pvVkGizODy9jq8esQdgEF114AgDqx3z2rFQxZ4ZGe8kXGTRX68dMTNpqG4vQ7louHbLgzCuwYgqP7T70Of9LYqCzzWNQ+xHX81VN2XyO6LZWyP4VnkikTZnsqK/mtUK35+iTwa55UdAwq9Y3cI6/EUYdmBpLT++WEHBQYAA+I4cBSo3zPWAZDnNQTmqxUPyjAwABzYAOU5kz3j6aU9j0JgrnaPtq9B4MDWBWXZjSxYX+/8A2uzm8L6kKG+W/oNBqer/7AH6D9fdtfuPIeqIeyWfoPD2W0Xq98QQfoNHueUk/wS6tYZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZR7i/DWNHftv1QcoAAAAASUVORK5CYII="/>
      <image
        id="image-8"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADSklEQVR4Xu3cPW4TQRyG8WdmvBISjS1kajdpKbALKprcgSv4TFyBO9BQURAKWhpXFFgobqjs3aHwbjIxFgshVl7b79NmTST/mI/9Fwk5Z5xmse8B93gZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZR7hB3wOP2XwWqu81T58nfr79lNd9z59asitnPgvVj5rJk8D0umHY9/wpJonTwVQDXtWJSYKq7zOnmCTOdcNwMGDaZCYhnycMCOLMZ6ECxmTGnOmK6ZLDuW4YxsSLzHmeM2VSOHtWzToEVjWc3U0NxHB2V02AVVPzZRRZ9X32FJPCSVDlzJD2rMmwTrA6x3ccEMNxdzOOcMYRzjjCSQ8+A1Q1DOezsDzUpWA+C1U3uxtFrcuH9MrJbK/Whxp8djO8mLgMideH+j33TXrl0F6tDzH4LIerTWYCLA/xe/4nqZVTtxMBiolAsbU96BdXDlcBFCcRUjijuJ0IBG4nAofY2nbHRKqTCCmct5/yOsEq3/0fXLVf4vghVk+3naXEtBsTqU4ipHBg/9aWYZgS0x81k/sCzWehevMyjK8bLopzRnq4Kocziqw2G65iYMHtF1Y1eXt43weovJXFyGUJEwOLzYYrtS0NICj+Xen23eMiRi4zjIsfrWNgUddcAcu+95LiHWac0s3h38GuY2Cx3vDxWWKhtqWBKA7Am5dhHBOXOXPB3SvuOsCKwLKp+ZLYbkklVIkSEy/IjNvz5ebfCbBsGt6PIl8VYUAYp9uKBgOm+75cWqTcnhkdFEDd3vD++LnAt1zz4d3nvEQ0WRzo3ZbKbqBg+260BwX+cVt87KRxuv5iFfV1sxVuNlypnjG7HQUO/H6O5MzwDysE9mx7HMFqKTsanK4OKbVjnQ5q97nyHNq9MBxLR4dTVkLt/uxYQcqOGufUk5sQuNuMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI5xxhDOOcMYRzjjCGUc44whnHOGMI9wvK05lmcYderwAAAAASUVORK5CYII="/>
      <image
        id="image-9"
        width="104"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAABjCAYAAAB320ViAAAACXBIWXMAAAsSAAALEgHS3X78AAADAElEQVR4Xu3cPVLbQACG4Xe1VpNKnox7mrQUwUWqNLlDruAzcaMU0HACuhQexqpohLwpJNmysREZnPDJfG/pkSj87I+0MISUEk63bOgC974ZSDwDiWcg8QwknoHEM5B4BhLPQOIZSDwDiWcg8QwknoHEM5B4BhLPQOIZSDwDiWcg8QwknoHEM5B4BhLPQOIZSDwDiWcg8QwknoHEM5B4BhLPQOIZSLzJ0AXv2WIe8tWaAmCaUV7fpGronnNLdgYt5iF/qLkIke8RLlfwaeiec0wWaLWmmEy4IjGr4fcUHofuOcckgRbzkAMzEjOAyMdc3kAUaLWmyCKXiWb/+cjJAe3Nnnzo+nNPDsizZzc5oAh5ShR49gCCQG43KaDFPOQ1FMGzZ5MUkPef50kBef95nhSQe57UYWkNVRYo039+B1I+lJWaQdOMcl1zF6DsPguQ11C0L7AnT/1QVgro+iZVEcoEmxGcaB4cuhF+yjqcfMI31UNZKaAj5SlRxBMveX2cdeICNA9l5YBqqEKgpDeLTr3MHcA5yc/9F8kBHdqHEhQxcvVQc/FWpCM4VQiUdW9QqCQH1C4xSwJLtl9Yvk7Nl/oWpGMzJ9AMimm2HRQqyQHB4VnEG5FeWNaqdjAs1fYfgKD6f7N/fg2zLPIjJb6wu0dUWeC+rrkFlkPvLb13nFmMXO3jZIH76olfnyP3BvqLBjbyKkBJYLmuuYs0+0eH1aHE9uEii1ySmLVnfKPBAWEgGESCFiq1m3wPqzl0Tc3J+B4MjAQHxIFgi9T9hc+BL7tri3UYZXPdWHBgBECwu48cWa5e02ZZfHridgw4MBKgrn2oF5Yw6M0ogG4J5BUPFkqNCqjr0ENA+3ukTf09CZoTijHBdI0SqF8fq//5WEH2Gz3QuSd5kuC2GUg8A4lnIPEMJJ6BxDOQeAYSz0DiGUg8A4lnIPEMJJ6BxDOQeAYSz0DiGUg8A4lnIPEMJJ6BxDOQeAYSz0DiGUg8A4lnIPEMJJ6BxDOQeAYSz0DiGUi8P8lCZnfN8jJjAAAAAElFTkSuQmCC"/>
      <image
        id="image-10"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADQklEQVR4Xu3cPW4aQQCG4XdYRoqUBhSRmiZtCkORk/gKnClXyB3SpA4p0qZxHWSxTSqYnRTswhobr2Ub+QO+tx674GF+LTnknHGa9boGuLfLOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOML1uwa8ZbNpiH8T7z8W/Pv6M6+6xp9bsjNnNg3xNjF+F5gsKwZd488xSZwGJvb5kgrGBcSunznHJHGWFYN+n0mVGYd8mTAgiDObhgiMyIy40BnTJIezrBj0Cj5nLnOfaSeF41lzNykcz5q7SeEUEHNmwG7WrEKgTHBxdxwQw9kvQFklfg97lF1jzzFpnAyrAspLfB0AcZxLTxonQEwwqE9xF5f0w2dmc3pbJhbAomv8c5pNQ2ze7oY9rSVUGof69Hast7XmDa/fZ5JhtUz84EhfguekjnO02o+rVWYMLI71JXhuUntOqu81tO41x9p32o+rAIr3KSmcYW9zrwns7jUZBkXB5DYxfi2g/Wci1fuUFE69GS8ILNh9i2OVN/vCa/zRrVnOioJJ80ykep+SwoGHZw8Q62/56CWz54F9JiL8RCSHc2D2vGh5m01DvL4Ko2XFp32YXuBmvWautqSB6Glt2KO8XTOP/c2SxuaDjFVmHPuwTMTrq7B4yr2kfVwmM6paD6sBypSYfyi46fo9b1FQ/affB5YggFWAksCiSvwu6uUvwarBai6WRX3SK4rtqaw941Yh8KdKfP/2K8vcbdrJ4sCjQFAj5XrpC2GzVxVQpvplIWcGAWK98d/DXa91Zw2I40AnULst1gMg2zG9wE1KzIEnLYtvmTwO3N83Dnzwj3Uys6XdSeDAnQfKUbNkweYF4dAs2c6kesnjBGZLu5PBaWpv9gDt/aU9bm8PWp0SStPJ4ey3j9V0qiDtTh7nnJN7IXC7jCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCPcf5CLalq+pkRNAAAAAElFTkSuQmCC"/>
      <image
        id="image-11"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADOElEQVR4Xu3cMW4aQRiG4Xd2WClSGpBFilRu0qYwFKnc+A6RcgLO5BulME1OQB1ksU2qZXdSLGMWhEUiW/YHfG8ZjVzw8M/ODlJCSgmnWXFsgXu/jCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCPc4NiC92w2DeXvho+fIn/uH1J9bP25JTs5s2koHxuuPwQmq5bhsfXnmCzOqmU4GDBpItcRymPrzzHJbW02DSUwJjEOcHHbWU5yclYtwyLyNXGZ21lODqc/NVzodpaTw/HUbJPDiVCmxJALnxoQw5lNQ9nAMBgGEMPxlrabFI63tN2kcNxuxhFO8obgLZtNQ5nv7kYFldIFq9TkNFCHQMUbXtmsWoYhchsit2oXrFI4o4KqbfgVoMr/FqBsYLi5OXjV8m1ESHwmMVa7YJXCuX9IdYQq9SYn0R2vX/tbnX+SiJGJ6tFdCueZys092/i1pifDlAO+tYlrgBComjfcTv8lOZxDz50EwxiZPDZcvxToAEwZ6LbTUbHdThWSwxkVVOs18yKwYAtUtqn7QF8CdAgGqAksgaXSSQ0Ece4fUn0VWTQN8/7BgBcAzaah/H4TxquWL3swqE4NCOJABwQsN9/o/rf5v4HytBSRu6Lgrg+D8NSA8EvoqKB6XDMvBx0I2w80A7FqKH/chAq6Z1V+icwvlnFzDC8HTPb+BkBdBBb1mvlV1JsagKD8P7I/84zI1aF37A6h254iVM3m+J1S9/PD5qh8CObnVWShODUgjgNboMGACYnxgQ8694T1DMjTGgLLdTcxsjBwAjiwc/81jvHgFnWsJ5S24RewVLtHO9RJ4OT6U7T53YdjU5I2702nhJI7KRzYTlG+B+s/X/rr9p5B9Smh5E4OZ799rNypgvQ7eZxzTvIl1HUZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZR7i/qnRLzHPlcGEAAAAASUVORK5CYII="/>
      <image
        id="image-12"
        width="103"
        height="99"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAABjCAYAAACG0B7vAAAACXBIWXMAAAsSAAALEgHS3X78AAADV0lEQVR4Xu3cvXISURyG8eecw844YwPjrIUVTVqLQGFlk3vwFrgmb8F7sLGySCxsbVJZyDjQWMHusWA3rIQMimHyAu/TZklm+OV88M9MQs4Zp1nc9YB7uowjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjnHGEM45wxhHOOMIZRzjjCGcc4YwjXG/XA0/ZZByKHxXPXyZ+vb/Oi13Pn1qyK2cyDsXPiuGzwGhW09/1/CkmidPCFD3eVIlhgmLXa04xSZxZTb/XY1RnhiGfJwwI4kzGoQBKMiVnumLa5HBmNf2YeJ05z3OmmxTOllWzCIF5BWd3UwMxnM1VE2BeV3wdROa7XnuKSeEkKHKmT3PWZFgkmJ/jZxwQw3F/ZhzhjCOccYSTHnwGKCroT8ZheqhLwWQcinZ2N4halw/plZNZXa0PNfhsZ3gxcRUSbw/1c/ZNeuXQXK0PMfjsDlfrzBCYHuLn/E9SK6dqJgJ0JgKdre3R3rgtMChOIqRwBnE1EQisJwKH2Nq6U2+gUJ1ESOG8v86LBPP8529w0czaysdYPdvmdwSmwMEuHfsmhQPbt7YM/ZQY/awY7gs0GYfi3WUoZzUXKTE6hvmdHM4gMl8uuYmBW9ZARZ1XZ8Q+QN1bWYxctdsZwqsGICj+X+ktB3aLsYiB26riBpju+lzS+QxTprQ+Y7rfa7Hk84vErXH+oeaNvYiRqwxl50uLAHMC07ria2J1y+pCdVFi4jWZstnG7lZcgGld83EQ+aYIA8I4AO8uQxkSb0Pm1eabS4OUmzOqhQKomhveNpS71wW+54pPH77kKaJJfwgdROazik95+7ZUtCsqZ8oYKevmjIobfxfqdG9bRDjpldPWnkG9HqMHVsOu7rbC5ZIb1TNms6PAgfvnSM70w2r1PAR1b9vjLy4RSh0NTluLlJqxTgu1+Vz3HNq8MBxLR4fTrQu1+bVjBel21DinntyEwK0zjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnDGEc44whlHOOMIZxzhjCOccYQzjnC/AWHKZPa6ooZQAAAAAElFTkSuQmCC"/>
    </defs>
    <title>dowlek</title>
    <g class="cls-1">
      <g
        id="Calque_2"
        data-name="Calque 2">
        <g
          id="Calque_4"
          data-name="Calque 4">
          <path
            class="cls-2"
            d="M239.8,318.31a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,1,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(172.64 270.88)"
              xlink:href="#image"/>
          </g>
          <ellipse
            class="cls-4"
            cx="233.18"
            cy="313.86"
            rx="3.12"
            ry="4.44"
            transform="translate(-141.42 201.79) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M186.34,318.31a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(119.64 270.88)"
              xlink:href="#image-2"/>
          </g>
          <ellipse
            class="cls-4"
            cx="179.72"
            cy="313.86"
            rx="3.12"
            ry="4.44"
            transform="translate(-152.03 169.82) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M355.66,318.31a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(288.64 270.88)"
              xlink:href="#image-3"/>
          </g>
          <ellipse
            class="cls-4"
            cx="349.03"
            cy="313.86"
            rx="3.12"
            ry="4.44"
            transform="translate(-118.41 271.09) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M302.2,318.31a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(235.64 270.88)"
              xlink:href="#image-4"/>
          </g>
          <ellipse
            class="cls-4"
            cx="295.57"
            cy="313.86"
            rx="3.12"
            ry="4.44"
            transform="translate(-129.03 239.11) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M243.8,276a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,1,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(176.64 228.88)"
              xlink:href="#image-5"/>
          </g>
          <ellipse
            class="cls-4"
            cx="237.18"
            cy="271.55"
            rx="3.12"
            ry="4.44"
            transform="translate(-115.32 195.78) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M190.34,276A15.19,15.19,0,0,1,160,276a15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(123.64 228.88)"
              xlink:href="#image-6"/>
          </g>
          <ellipse
            class="cls-4"
            cx="183.72"
            cy="271.55"
            rx="3.12"
            ry="4.44"
            transform="translate(-125.93 163.81) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M351.66,276a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(284.64 228.88)"
              xlink:href="#image-7"/>
          </g>
          <ellipse
            class="cls-4"
            cx="345.03"
            cy="271.55"
            rx="3.12"
            ry="4.44"
            transform="translate(-93.9 260.29) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M298.2,276a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(231.64 228.88)"
              xlink:href="#image-8"/>
          </g>
          <ellipse
            class="cls-4"
            cx="291.57"
            cy="271.55"
            rx="3.12"
            ry="4.44"
            transform="translate(-104.51 228.32) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M247.8,234a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(180.64 186.88)"
              xlink:href="#image-9"/>
          </g>
          <ellipse
            class="cls-4"
            cx="241.18"
            cy="229.51"
            rx="3.12"
            ry="4.44"
            transform="translate(-89.38 189.83) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M194.34,234A15.19,15.19,0,0,1,164,234a15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(127.64 186.88)"
              xlink:href="#image-10"/>
          </g>
          <ellipse
            class="cls-4"
            cx="187.72"
            cy="229.51"
            rx="3.12"
            ry="4.44"
            transform="translate(-99.99 157.85) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M347.66,234a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,1,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(280.64 186.88)"
              xlink:href="#image-11"/>
          </g>
          <ellipse
            class="cls-4"
            cx="341.03"
            cy="229.51"
            rx="3.12"
            ry="4.44"
            transform="translate(-69.55 249.55) rotate(-36.73)"/>
          <path
            class="cls-2"
            d="M294.2,234a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
          <g class="cls-3">
            <use
              transform="translate(227.64 186.88)"
              xlink:href="#image-12"/>
          </g>
          <ellipse
            class="cls-4"
            cx="287.57"
            cy="229.51"
            rx="3.12"
            ry="4.44"
            transform="translate(-80.16 217.58) rotate(-36.73)"/>
          <path
            class="cls-5"
            d="M209.42,208.76s-9.6,48.81-9.6,64v68"/>
          <path
            class="cls-5"
            d="M303.86,208.76s9.6,48.81,9.6,64v68"/>
          <line
            class="cls-5"
            x1="256.64"
            y1="340.78"
            x2="256.64"
            y2="233.95"/>
          <g>
            <rect
              class="cls-6"
              x="119.03"
              y="169.49"
              width="11.34"
              height="77.93"
              transform="translate(-117.5 226.98) rotate(-63.49)"/>
            <rect
              class="cls-6"
              x="50.93"
              y="134.7"
              width="8.07"
              height="77.93"
              transform="translate(-124.97 145.32) rotate(-63.49)"/>
            <path
              class="cls-6"
              d="M21.87,133.43c2.64,4.83-.91,23.27-.91,23.27s-13.37,14.12-18,14.73-3.46-12.35,1.78-22.84S19.6,129.3,21.87,133.43Z"/>
            <path
              class="cls-2"
              d="M19,134.59c-2-1-8.34,5.3-13,14.64s-5.92,18.18-3.88,19.2,6.51-6.65,11.17-16S21,135.61,19,134.59Z"/>
          </g>
          <path
            class="cls-6"
            d="M390.27,172.62h0a6,6,0,0,1,6,6v75a0,0,0,0,1,0,0H384.23a0,0,0,0,1,0,0v-75a6,6,0,0,1,6-6Z"
            transform="translate(472.14 -223.95) rotate(71.97)"/>
          <g>
            <path
              class="cls-2"
              d="M373.23,227.87a13.88,13.88,0,1,1-.19-2.31A14.6,14.6,0,0,1,373.23,227.87Z"/>
            <g class="cls-7">
              <image
                width="101"
                height="96"
                transform="translate(308.64 181.88)"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABgCAYAAAAEsbx8AAAACXBIWXMAAAsSAAALEgHS3X78AAAC6klEQVR4Xu3cvW7TUACG4ffYORISiyMU5iysHUgGJhbugVvINfWOGNqlV9CZqIoXpsQ+DLYbFyUxpRS+xN8724ufnt9IDSklnFbZ0APu32cUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwSZDD/zPVssQv1e8fZ/z4/ombYeev5RkR8pqGeJDxfxNYLGpKYaev6QkUTqQOOFTlTPPIQ69c0lJomxqismERZ2YhzQuEBBEWS1DBGYkZoxshHTJoWxqiiznKjGudaSfHEoOMSUKRjpKQAxltQyxgiKMGATEUA5MXdsQKCsYzRkFxFB+nboClHXF3TSjHHj1opJCcU3SKIlmOhvbiV767ot2OnuNE/1qGWKHPc0ole7WpEZK1S7svPLC3l3jZDlfQs5ntZEohTLNmoU9sF/YA8QKivak/+L692op8YHE7DVG4kuSQrm+SdscytQbKX9zXemD1Ik5gOKWWwoFDk5hsb0Hm71ktBwAiapbbjmUQ1NYgiLPWTxUzP8E5hAIsCWwBtZKizwIorQfaN1+sMfRUqfmoz4X5giI9MFUcks8zSgfdtzGSYNB8yE7GDYV8evHsD61le1teWex/W2G/YIuO0oAguq/wD32Fw5sA5QE1nXFXU6zUHdAfYws54rErL1Le3w/C9xvd3x7l3NvlGd2AgZanNRuDHpAxREMOAMQEEeBQZiuPRDEQxjd6NrtuFUGgTNAgT3MZMLiyAg41pOpDji5Dql0FijwdOHOcq7aK/6TddMaZ4LRdTYoXR3O71yN9DcAQ88qdXYoY0ju8OiMIplRBDOKYEYRzCiCGUUwowhmFMGMIphRBDOKYEYRzCiCGUUwowhmFMGMIphRBDOKYEYRzCiCGUUwowhmFMGMIphRBDOKYEYRzCiCGUUwowhmFMGMIthPFuJBFT76tLgAAAAASUVORK5CYII="/>
            </g>
            <path
              class="cls-4"
              d="M373,225.56a4.13,4.13,0,0,1-4-1.9c-1.35-1.8-1.41-4-.14-5a1.74,1.74,0,0,1,.61-.3A13.84,13.84,0,0,1,373,225.56Z"/>
            <path
              class="cls-2"
              d="M380.64,274.69a14,14,0,1,1-4.07-13.6,13.81,13.81,0,0,1,4,6.49,14,14,0,0,1,.48,2.78A13.84,13.84,0,0,1,380.64,274.69Z"/>
            <g class="cls-7">
              <image
                width="101"
                height="96"
                transform="translate(316.64 225.88)"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABgCAYAAAAEsbx8AAAACXBIWXMAAAsSAAALEgHS3X78AAADG0lEQVR4Xu3csU7bQADG8f/5bKlSF0conbN0ZSAZOnXhHXiFPBOv0Hfo3gE6sHZhboTipZOxr4NjYqJAaBXBl/D9Rzhl8M93uTskQkoJp1W2a4B7/YwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwiWL5rwFs1n4Xid8PHT5E/l1ep3jX+mJKcKfNZKO4aJh8C02VLuWv8sSWJsmwp85xpE5lEKHaNP7bklq/5LBTAmMQ4wLtatvrkZsqypcwip4n3t2z1yaFEKFKi5B0uW31SKPNZKBoowzsGATEUL11dUiheurqkUFyX3Jb4tZrPQtEfTEcZldKtgfRMCVA0UK7OLnurvzHIIuch8lXt1kAaJdF98e/7ofU3BinxmcRY7dZACqWBOgQq1if5IiXKfT604Y0Be/zcfSaFMsqo2oabAFX/s30uYf2yFSPT1ba7DoGqEbvOkUK5vEp1hCoNHtK+lrAepMj50iYmQBHoXoJRtn4JFJJCge1L2GqpGf/vbNkGAtQEFsBCaecFgijblrAEZYxM7xom/wrzBAiqswQgKP5jz4uzMM4i5ynxmfWXcZ0FbpuGa2DxkrPFUyB0s/FX2/D928+0eOYj3iTJw+Moo7q757rIKQYPs2gTkyyjJLBYNtxcnIWtOIOD4bjImW6CZIHb+p7rk6g3S0B0psCzbzlAHaAisGgbbiKPH26z2hyQGK92WZsgP04it7tm2lsliwI7YWCFM9ytQbeN3sCAAwEBcRRYw+Q50y1v/kt6mFX33ZIlDQIHgAKPvyOyyGlK3R/CngF6mEEhdLssXrg5UOggUPp6nLg65fdAm+N6iEh3Wj8UjL6DQhk2BNr83SFCDDtYlGNO7kTvjCKZUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCLYX8vyOwbHB4a9AAAAAElFTkSuQmCC"/>
            </g>
            <path
              class="cls-4"
              d="M380.53,267.58a1.45,1.45,0,0,1-.21.22c-1.2,1-3.37.48-4.84-1.23s-1.69-3.93-.49-5a2.26,2.26,0,0,1,1.58-.52A13.81,13.81,0,0,1,380.53,267.58Z"/>
            <path
              class="cls-2"
              d="M382.36,318.09a14,14,0,1,1-14.52-17.29,14,14,0,0,1,14.07,9.11,14.08,14.08,0,0,1,.45,8.18Z"/>
            <g class="cls-7">
              <image
                width="101"
                height="96"
                transform="translate(318.64 268.88)"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABgCAYAAAAEsbx8AAAACXBIWXMAAAsSAAALEgHS3X78AAAC5UlEQVR4Xu3cvU7bUByG8ef4Q6rUxVGVDl2apSsDydCJpfdQqVeQa+oddSgLV8DcCMVLuzj26WCbmAiwoBF9Td5nxSz++Rwf/5EIMUacVsnYBe7lM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pg2dgF/6v1KuS/at6+T/n9/Wesxq5/TUmulPUq5Dc1izeB5bahGLv+tSWJsm0osoxlnbJIIR+7/rUlt32tVyEH5kTmAU5q2+qTWynbhiJJOYuc3rbVJ4UyXCWc4LbVJ4XiVdImhZJCHiMFJ7xKQAzFtRlFMKMIJved8lKtVyHvpwWzhFJplCO9UgLkNRTdUflo9WOcJOVLSLlQG+VIo0TaI/Ixb1oPkmd8jpGPQfCkJ41Cd0Q+1vxrCNJEFgH+NDVXs4Ry7HdfMimUGqoQKBnMvI61hR2CAHmEKkXrfQJiKLOEsqm5Cuyf3AhFmrK8qVn8C0w/ee5B6B6AWnDoKYXSPbEbAhv2NytvYvuEPwdmvQr51/MwT+DDcKYWaB8Ata0LxFDg/tXCM2GGpywSLgYztaqD36htXSCI8sBqgSfCHJyyPkVuV0mVBK53Oy4VVwmIfjzOEsqbHZd51kKwP331MGxr8m/n4cGbWkOR332HQAdS7fjxLuVacZUABNX/S3zfaWnw4ypAGR95SYf2dDWcOE8CBIRRYA+TZSyJzA9u8lOaDAiIo8CdGdU8STl7As7tagqBcrfjcgogMAGUvkOc7o9hDxZCe4pLab9F1IaOjzUZlL4eZ2z0MjWIYZNDOYXkvlOcUSQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQT7C/SJFtSZxteZAAAAAElFTkSuQmCC"/>
            </g>
            <path
              class="cls-4"
              d="M381.91,309.91c-1.22.93-3.3.36-4.73-1.3s-1.69-3.93-.49-5a1.61,1.61,0,0,1,.3-.21A13.89,13.89,0,0,1,381.91,309.91Z"/>
            <path
              class="cls-2"
              d="M137.55,227.87a13.94,13.94,0,0,0,14,14,14,14,0,1,0-14-14Z"/>
            <g class="cls-7">
              <image
                width="100"
                height="96"
                transform="translate(101.64 181.88)"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABgCAYAAADrc9dCAAAACXBIWXMAAAsSAAALEgHS3X78AAAC00lEQVR4Xu3cPXLaUBiF4ffqp5cnQx2atCkChas02UO2wJq8oxSmyQrcaxhUuRHSTSHJxowNzgTDUXTe1kiFHr4r6TLjEGPE6ZSc+oC7bAYRyyBiGUQsg4hlELEMIpZBxDKIWAYRyyBiGUQsg4hlELEMIpZBxDKIWAYRyyBiGUQsg4hlELEMIpZBxDKIWAYRyyBiGUQsg4hlELEMIpZBxDKIWAYRKzv1gf+l1TLk25YC4CahuruP9aljrtFkJmTbUoSU70nKj03DfLUM+aljrtFkJgQgQB4jn7MMtg0VUJ465tJNZkJuEqq24XeARyIzYKY4JZMBubuPdQpVhDpCkaR8He4pSk0G5KBcdUqmCoLqlEwWhO4GX6TgCXFvZxCxDCKWQcSSelMfy37TRyY1IR+537RahryBIog9VR0mNSEp5G1kFmF27v2mbdu9d8So9d5xmNSE7HXWN+n+HLP+nMP56hCoGpBaFqVAmv4i0e83pSmLcyxdT9PB83QEus3Gm4Tq2LGXTgpkb0e2olu+5nnG7b+gvDUdBEqgVHtwkALpL07ZX6yaPZRty5ef38JfLWGrZcg3DfM0ZTGG6QCxmzp0U7LZsc6zDoMeJUkoCJSbHevVMjwc+2bvPT7P8ozFcJ7+z7LTARAU/03s8M3OM24PL2YSeGga1indDXn4lm/b543Cpt/JpXtiKw6Pr3f8+pRyFPVaSYLAcZTQ/9AUQrf0AOw/0gbIDyBgBBggDAJHUYbq/gGAVwBefiZQ7naslTFAHASeUbKMxStL0LGeIPopKsewHSMPAi9v0u99295bzkYBMTQKkKEB5j2/8g03/LFADI0KZApJvRg6g8hlELEMIpZBxDKIWAYRyyBiGUQsg4hlELEMIpZBxDKIWAYRyyBiGUQsg4hlELEMIpZBxDKIWAYRyyBiGUQsg4hlELEMIpZBxDKIWAYRyyBiGUQsg4j1ByJrIuDvcrZ+AAAAAElFTkSuQmCC"/>
            </g>
            <path
              class="cls-2"
              d="M129.77,270.35a14.4,14.4,0,0,0,.37,4.33,14,14,0,1,0-.37-4.33Z"/>
            <g class="cls-7">
              <image
                width="100"
                height="96"
                transform="translate(93.64 225.88)"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABgCAYAAADrc9dCAAAACXBIWXMAAAsSAAALEgHS3X78AAADDElEQVR4Xu3csU7bQBzH8e/5fFKlLo4qd2Bi6doBMjCx9B2Q+gR5Jh6mbwALaxfmRiheOhn7OthWQ6AECZT+Qn6flTiR+OZ/50ukhJwzpqPY9gDbLQcR4yBiHESMg4hxEDEOIsZBxDiIGAcR4yBiHESMg4hxEDEOIsZBxDiIGAcR4yBiHESMg4hxEDEOIsZBxDiIGAcR4yBiHESMg4hxEDEOIsZBxDiIGAcRU257wHuymIe06qkAZgXN5VVut12zawc1IaueKkTOQ+R8CqPmoIJESGTqkDkC6sU8pG3X7NpBBZlkqIrIV8UpOcggjJOC4JQcahDZKTnYIEDKmSqCJ8T+zUHEOIgYqZP6Yh7Sr46PnyO/FU/RuyA1Iaue6kPg9K7jWO12dFekgkRIXeQ4lZwdahSpJQsgZFIPR2VJu+pogOW2a15iMQ+pg6qApPwLYVITsubNT9KrfjgIZrQOgpukgnTQhkADtBmqGN9mPxmvr8fI03O1IdB0IHXzIBVkVtD0HTcBGiD1+fX7yWIe0l3HcYycrk9HYHitWUHz3PW7JhVkvNVdElgyvHNfFWWKkUrO+swxa9MxvsZS7fZablOfFTSrjpuioM4MS8wYhVVHujgJy5d82/dcjCJw295z/SlqTQdAUPxV0ouTUBeRbznzhfU1HxoCy77jBngyzNrXtHWMnG7EIMCy7/kxK/i5Ler/IDchMEzJ3T3XqRymg+EfmvKwMVdFQU1gueq4+X4SHrzLu/FjdTJ1n6l4+Gmu7FI1kQxyeZXbxTzc3t1DWdKSqccN+VGYfuMuqRg/VmcjxDRd96JL1URyyZpsW35e4EXLnBLpIJNpgy5LTnOmCsOUbE7Bur0LMdmLIPB3WiKkaZ8Yl6ZHQhjOGOxRiMneBFm3Huepv3fQ7luIyV4Gec+kTurmIHIcRIyDiHEQMQ4ixkHEOIgYBxHjIGIcRIyDiHEQMQ4ixkHEOIgYBxHjIGIcRIyDiHEQMQ4ixkHEOIgYBxHjIGIcRIyDiHEQMQ4ixkHEOIgYBxHjIGL+AHJFICMmE9xZAAAAAElFTkSuQmCC"/>
            </g>
            <path
              class="cls-2"
              d="M128.05,313.76a14.14,14.14,0,0,0,.37,4.33,14,14,0,1,0-.37-4.33Z"/>
            <g class="cls-7">
              <image
                width="101"
                height="96"
                transform="translate(91.64 268.88)"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABgCAYAAAAEsbx8AAAACXBIWXMAAAsSAAALEgHS3X78AAAC5klEQVR4Xu3cMXLTQBiG4Xe10gwzNPIwpnZDCQV2kYomd+AKPhM3oiBNTuAaT8ZqqBxpKSQnxiRWGDvJJ/S9tZTCj/ZfrzyTkFLCaZX1XeBePqMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIZhTBjCKYUQQzimBGEcwoghlFMKMIlvdd8L+1XITiZ83b95Ff336kbd/1r9HoVsqmoXyT8XHTUPZd+1qNDiVCkWAKTJeLUPRd/xqNDgWgyZhkkU+qq2WUKCFRkHRXyyhRABKUqqtltChAkRJlBK8UpQIUNZRqI2zUKKojbNQoiI6wUaEsF6GooQxiCIdJvWZZLkKxGyWTjOrcr0E2TTuuUtIaV4dJrZRNQxkiX7LI5U3N7NwbcOzGFV4pTy9C0SSmCaZ5DpuaClj33XdC2xCoajjrijw1qZWy19lP3A/tJwGqpuZ6klEdu/elk0KpuycX2CYoY2R+rjF2t59wv58k2EbOv3edmhTKJGuf3AAV7SibFTkXp8J090671bf7O5KjC8RQuid2TWBN+2GdDLNchOKmZhYj8/1Vojq6QAwF/lotcALMDqTIuWgSM/ZWSQe/VhtdAEHxv61+/RymWeQyJT6w90FmgVVdcwWsj51j9s470xiZH4JkgdX2lu/vIiujPLFjT3iAisC6qbmOPDx66u6dFu3X6/1ziTwIiKLAURjocNIjm3Rof/I9PCQOAgSEUeAeJs+ZP/DU/0uDAQFxFPhzf3hkJD3W3WoKger2lqshgMAAUHYd4vS9VAyh/RYXac8iz/GC87kaDMquHU7fbyBDg9hvcChjSO7w6IwimVEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwimFEEM4pgRhHMKIIZRTCjCGYUwYwi2G/7Xip5By43fQAAAABJRU5ErkJggg=="/>
            </g>
            <path
              class="cls-6"
              d="M352.64,176.6a212.84,212.84,0,0,1,6.45,20.52c1.87,6.84,3.46,13.69,4.91,20.53,2.87,13.68,5.06,27.36,6.76,41s2.85,27.36,3.63,41l.47,10.26c.11,3.42.27,6.84.32,10.26s.17,6.84.17,10.27l0,5.13v1.28c.1.43-.41.85-1.8,1.28-.3.11-.81.21-1.22.32-.23.05-.4.11-.68.16l-.83.16L370,339l-1,.16c-.36.06-.62.11-1.06.16l-1.28.16-2.55.32-3.49.32-3.53.33-.89.08-1.18.08-2.37.16h-192l-2.37-.16-1.18-.08-.89-.08-3.53-.33-3.5-.32-2.54-.32-1.28-.16c-.44-.05-.7-.1-1.06-.16l-1-.16-.85-.16-.83-.16c-.28-.05-.45-.11-.68-.16-.41-.11-.92-.21-1.22-.32-1.39-.43-1.91-.85-1.8-1.28v-1.28l0-5.13c0-3.43.11-6.85.17-10.27s.21-6.84.31-10.26l.48-10.26c.78-13.68,1.93-27.36,3.63-41s3.89-27.36,6.76-41c1.44-6.84,3-13.69,4.91-20.53a212.84,212.84,0,0,1,6.45-20.52Z"/>
            <polygon
              class="cls-8"
              points="375.4 364.1 256.64 369.43 137.89 364.1 137.89 336.93 256.64 340.78 375.4 336.93 375.4 364.1"/>
            <path
              class="cls-2"
              d="M239.8,318.31a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,1,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(172.64 270.88)"
                xlink:href="#image"/>
            </g>
            <ellipse
              class="cls-4"
              cx="233.18"
              cy="313.86"
              rx="3.12"
              ry="4.44"
              transform="translate(-141.42 201.79) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M186.34,318.31a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(119.64 270.88)"
                xlink:href="#image-2"/>
            </g>
            <ellipse
              class="cls-4"
              cx="179.72"
              cy="313.86"
              rx="3.12"
              ry="4.44"
              transform="translate(-152.03 169.82) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M355.66,318.31a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(288.64 270.88)"
                xlink:href="#image-3"/>
            </g>
            <ellipse
              class="cls-4"
              cx="349.03"
              cy="313.86"
              rx="3.12"
              ry="4.44"
              transform="translate(-118.41 271.09) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M302.2,318.31a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(235.64 270.88)"
                xlink:href="#image-4"/>
            </g>
            <ellipse
              class="cls-4"
              cx="295.57"
              cy="313.86"
              rx="3.12"
              ry="4.44"
              transform="translate(-129.03 239.11) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M243.8,276a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,1,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(176.64 228.88)"
                xlink:href="#image-5"/>
            </g>
            <ellipse
              class="cls-4"
              cx="237.18"
              cy="271.55"
              rx="3.12"
              ry="4.44"
              transform="translate(-115.32 195.78) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M190.34,276A15.19,15.19,0,0,1,160,276a15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(123.64 228.88)"
                xlink:href="#image-6"/>
            </g>
            <ellipse
              class="cls-4"
              cx="183.72"
              cy="271.55"
              rx="3.12"
              ry="4.44"
              transform="translate(-125.93 163.81) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M351.66,276a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(284.64 228.88)"
                xlink:href="#image-7"/>
            </g>
            <ellipse
              class="cls-4"
              cx="345.03"
              cy="271.55"
              rx="3.12"
              ry="4.44"
              transform="translate(-93.9 260.29) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M298.2,276a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(231.64 228.88)"
                xlink:href="#image-8"/>
            </g>
            <ellipse
              class="cls-4"
              cx="291.57"
              cy="271.55"
              rx="3.12"
              ry="4.44"
              transform="translate(-104.51 228.32) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M247.8,234a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(180.64 186.88)"
                xlink:href="#image-9"/>
            </g>
            <ellipse
              class="cls-4"
              cx="241.18"
              cy="229.51"
              rx="3.12"
              ry="4.44"
              transform="translate(-89.38 189.83) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M194.34,234A15.19,15.19,0,0,1,164,234a15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(127.64 186.88)"
                xlink:href="#image-10"/>
            </g>
            <ellipse
              class="cls-4"
              cx="187.72"
              cy="229.51"
              rx="3.12"
              ry="4.44"
              transform="translate(-99.99 157.85) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M347.66,234a15.19,15.19,0,1,1-30.38,0,15.19,15.19,0,1,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(280.64 186.88)"
                xlink:href="#image-11"/>
            </g>
            <ellipse
              class="cls-4"
              cx="341.03"
              cy="229.51"
              rx="3.12"
              ry="4.44"
              transform="translate(-69.55 249.55) rotate(-36.73)"/>
            <path
              class="cls-2"
              d="M294.2,234a15.19,15.19,0,0,1-30.38,0,15.19,15.19,0,0,1,30.38,0Z"/>
            <g class="cls-3">
              <use
                transform="translate(227.64 186.88)"
                xlink:href="#image-12"/>
            </g>
            <ellipse
              class="cls-4"
              cx="287.57"
              cy="229.51"
              rx="3.12"
              ry="4.44"
              transform="translate(-80.16 217.58) rotate(-36.73)"/>
            <path
              class="cls-9"
              d="M200.32,340.78h-1v-68c0-15,9.51-63.58,9.61-64.07l1,.19c-.1.49-9.59,48.93-9.59,63.88Z"/>
            <path
              class="cls-9"
              d="M314,340.78h-1v-68c0-14.95-9.5-63.39-9.59-63.88l1-.19c.09.49,9.61,49,9.61,64.07Z"/>
            <rect
              class="cls-9"
              x="256.14"
              y="233.95"
              width="1"
              height="106.82"/>
            <g>
              <rect
                class="cls-6"
                x="119.03"
                y="169.49"
                width="11.34"
                height="77.93"
                transform="translate(-117.5 226.98) rotate(-63.49)"/>
              <rect
                class="cls-6"
                x="50.93"
                y="134.7"
                width="8.07"
                height="77.93"
                transform="translate(-124.97 145.32) rotate(-63.49)"/>
              <path
                class="cls-6"
                d="M21.87,133.43c2.64,4.83-.91,23.27-.91,23.27s-13.37,14.12-18,14.73-3.46-12.35,1.78-22.84S19.6,129.3,21.87,133.43Z"/>
              <path
                class="cls-2"
                d="M19,134.59c-2-1-8.34,5.3-13,14.64s-5.92,18.18-3.88,19.2,6.51-6.65,11.17-16S21,135.61,19,134.59Z"/>
            </g>
            <path
              class="cls-6"
              d="M390.27,172.62h0a6,6,0,0,1,6,6v75a0,0,0,0,1,0,0H384.23a0,0,0,0,1,0,0v-75a6,6,0,0,1,6-6Z"
              transform="translate(472.14 -223.95) rotate(71.97)"/>
            <g>
              <path
                class="cls-10"
                d="M388.38,51.5c-5.84-3.43-17.12-1.84-38.16-1.62-25-16.7-57.53-26.83-93.21-26.83s-68.24,10.13-93.2,26.83c-18.81-2.82-39.91-4.26-42.46-2-3.32,3-2.26,28,5.2,41.08a88.17,88.17,0,0,0-11.7,43.5c0,60.44,63.65,109.43,142.16,109.43s142.16-49,142.16-109.43A88.27,88.27,0,0,0,387.48,89C393.57,75.16,392,53.62,388.38,51.5Z"/>
              <path
                class="cls-11"
                d="M322.68,69.21A66.77,66.77,0,0,0,257,124,66.82,66.82,0,1,0,257,148a66.78,66.78,0,1,0,65.67-78.77Z"/>
              <g>
                <path
                  class="cls-12"
                  d="M240.75,210.37c.56,5.92,5.2,12.62,16.19,12.62s17.36-6.7,16.2-12.62S244.2,201.66,240.75,210.37Z"/>
                <path
                  class="cls-13"
                  d="M256.94,188.6c5.22,0,11.58,6.71,16.2,21.77-5.19-.81-9.83,5.13-16.2,18.55-6.37-13.42-11-19.36-16.19-18.55C245.38,195.31,251.74,188.6,256.94,188.6Z"/>
              </g>
              <g>
                <g>
                  <path
                    class="cls-14"
                    d="M241.67,135a50.35,50.35,0,1,1-50.32-50.33A50.35,50.35,0,0,1,241.67,135Z"/>
                  <path
                    class="cls-8"
                    d="M237.31,135.53c-.52,25.71-19.73,47.85-46.34,47.3-25.22-.49-44.12-22.93-45.58-47-1.6-26.24,20.13-49.29,46.31-48.78s45.13,24.25,45.61,49c.05,2.67,8.77,1,8.7-2.33-.59-29.94-25.52-52.14-55-51.55-29.09.59-53.72,24.09-54.32,53.3-.62,30.22,25.94,52.73,55,52.16s53.75-24.1,54.31-53.31C246.08,130.85,237.36,132.72,237.31,135.53Z"/>
                </g>
                <path
                  class="cls-8"
                  d="M224.48,135a33.15,33.15,0,1,1-33.13-33.15A33.14,33.14,0,0,1,224.48,135Z"/>
                <path
                  class="cls-11"
                  d="M180.53,155.28c0,4.62-6.27,8.34-14,8.34s-14-3.72-14-8.34,6.29-8.32,14-8.32S180.53,150.71,180.53,155.28Z"/>
                <path
                  class="cls-11"
                  d="M228.61,114.25c0,9-8.09,16.32-18,16.32s-18-7.33-18-16.32,8.05-16.36,18-16.36S228.61,105.23,228.61,114.25Z"/>
              </g>
              <g>
                <g>
                  <path
                    class="cls-14"
                    d="M374.67,135a50.35,50.35,0,1,1-50.32-50.33A50.35,50.35,0,0,1,374.67,135Z"/>
                  <path
                    class="cls-8"
                    d="M370.31,135.53c-.5,25.71-19.73,47.85-46.35,47.3-25.21-.49-44.1-22.93-45.57-47-1.6-26.24,20.14-49.29,46.31-48.78s45.11,24.25,45.61,49c.07,2.67,8.79,1,8.73-2.33-.62-29.94-25.54-52.14-55.08-51.55-29.07.59-53.73,24.09-54.3,53.3-.63,30.22,25.93,52.73,55,52.16s53.75-24.1,54.33-53.31C379.1,130.85,370.38,132.72,370.31,135.53Z"/>
                </g>
                <path
                  class="cls-8"
                  d="M357.48,135a33.16,33.16,0,1,1-33.13-33.15A33.15,33.15,0,0,1,357.48,135Z"/>
                <path
                  class="cls-11"
                  d="M313.54,155.28c0,4.62-6.29,8.34-14,8.34s-14-3.72-14-8.34,6.28-8.32,14-8.32S313.54,150.71,313.54,155.28Z"/>
                <path
                  class="cls-11"
                  d="M361.63,114.25c0,9-8.08,16.32-18.05,16.32s-18-7.33-18-16.32,8.07-16.36,18-16.36S361.63,105.23,361.63,114.25Z"/>
              </g>
            </g>
            <ellipse
              class="cls-11"
              cx="262.67"
              cy="40.46"
              rx="10.47"
              ry="4.84"/>
            <path
              class="cls-2"
              d="M337.32,3.11h0A11.65,11.65,0,0,1,349,14.76V38.23a0,0,0,0,1,0,0h-23.3a0,0,0,0,1,0,0V14.76A11.65,11.65,0,0,1,337.32,3.11Z"
              transform="translate(90.73 -210.43) rotate(39.64)"/>
            <path
              class="cls-2"
              d="M160.31,3.11h23.3a0,0,0,0,1,0,0V26.58A11.65,11.65,0,0,1,172,38.23h0a11.65,11.65,0,0,1-11.65-11.65V3.11A0,0,0,0,1,160.31,3.11Z"
              transform="translate(317.57 -73.11) rotate(140.36)"/>
            <path
              class="cls-6"
              d="M350.16,50.85h-191a115.14,115.14,0,0,1,191,0Z"/>
            <g>
              <circle
                class="cls-2"
                cx="254.64"
                cy="50.85"
                r="34.15"/>
              <circle
                class="cls-8"
                cx="254.64"
                cy="50.85"
                r="26"/>
              <ellipse
                class="cls-11"
                cx="264.44"
                cy="37.58"
                rx="4.22"
                ry="7.61"
                transform="translate(99.67 247.81) rotate(-60)"/>
              <ellipse
                class="cls-11"
                cx="242.64"
                cy="64.13"
                rx="2.8"
                ry="3.8"
                transform="matrix(0.5, -0.87, 0.87, 0.5, 65.79, 242.2)"/>
            </g>
            <polygon
              class="cls-9"
              points="296.84 51.1 276.43 15.08 231.3 15.08 212.45 51.08 211.56 50.62 230.7 14.08 277.02 14.08 297.71 50.6 296.84 51.1"/>
            <ellipse
              class="cls-4"
              cx="345.41"
              cy="10.9"
              rx="3.12"
              ry="4.44"
              transform="translate(93.46 247.44) rotate(-45)"/>
            <ellipse
              class="cls-4"
              cx="169.9"
              cy="10.6"
              rx="2.81"
              ry="4.43"
              transform="translate(100.07 165.84) rotate(-69.35)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CyBuildReaped',
  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 127,
    },
  },
}
</script>

<style lang="scss" scoped>
#dowlek {
  transform-origin: center;
}

.svg-container {
  isolation: isolate;
}

.cls-1 {
  isolation: isolate;
}

.cls-2 {
  fill: #ffb339;
}

.cls-3 {
  opacity: 0.63;
}

.cls-4,
.cls-9 {
  fill: #fff3d2;
}

.cls-5 {
  stroke: #fff3d2;
  fill: none;
  stroke-miterlimit: 10;
}

.cls-5,
.cls-9 {
  mix-blend-mode: overlay;
}

.cls-6 {
  fill: cy-get-color("primary");
}

.cls-7 {
  opacity: 0.21;
}

.cls-8 {
  fill: #231f20;
}

.cls-10 {
  fill: #0f9797;
}

.cls-11 {
  fill: cy-get-color("white");
}

.cls-12 {
  fill: #f05825;
}

.cls-13 {
  fill: #ff9800;
}

.cls-14 {
  fill: #86353b;
}
</style>
